var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('master-page',{attrs:{"icone":"mdi-account-arrow-left","titulo":"Receptor","subTitulo":"Lista dos receptores para uso no sistema."}},[_c('v-row',{staticClass:"toolbar",attrs:{"align":"center"}},[_c('v-text-field',{staticClass:"mx-2",attrs:{"label":"Pesquisa rápida","clearable":"","single-linehide-details":""},on:{"change":function($event){return _vm.Atualizar()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.ExportarExcel('tabela')}}},on),[_c('v-icon',[_vm._v("mdi-file-excel-outline")])],1)]}}])},[_c('span',[_vm._v("Exportar (Excel)")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.Atualizar()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Atualizar")])]),(_vm.app.role == 'Admin' || _vm.app.role == 'Residuo'|| _vm.app.role == 'ResiduoArquivista')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.AbrirDialogCadastro()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,3425255280)},[_c('span',[_vm._v("Novo")])]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"tabela","headers":_vm.headers,"items":_vm.lista,"options":_vm.options,"server-items-length":_vm.totalLista,"loading":_vm.loading,"footer-props":{ showFirstLastPage: true}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.app.role == 'Admin' || _vm.app.role == 'Residuo'|| _vm.app.role == 'ResiduoArquivista')?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.AbrirDialogCadastro(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e(),(_vm.app.role == 'Admin' || _vm.app.role == 'Residuo'|| _vm.app.role == 'ResiduoArquivista')?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.Excluir(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])]):_vm._e()]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ativo.toSimNao())+" ")]}}],null,true)}),_c('cadastro-receptor',{attrs:{"item":_vm.item},on:{"fechou":function($event){_vm.dialogCadastro = false},"salvou":function($event){return _vm.Atualizar()}},model:{value:(_vm.dialogCadastro),callback:function ($$v) {_vm.dialogCadastro=$$v},expression:"dialogCadastro"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }